import { cn } from '~/common/utils/styles';

export enum SideNavPage {
    Launchpad = 'launchpad',
    Services = 'services',
    Investments = 'investments',
}

export const ParallaxSideNav = ({ page }: { page: SideNavPage }) => {
    const handleClick = (elementId: SideNavPage) => {
        const element = document.getElementById(elementId);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <div className="sticky top-0 z-20 opacity-100 xs:hidden sm:block">
            <div className="absolute top-28 right-20 h-[160px] w-[36px] flex-col items-start gap-6 p-0 mix-blend-normal xs:hidden sm:flex">
                <svg width={36} height={36} className="h-9 w-9" fill="white">
                    <use
                        href="/images/landing/symbols/clubhouse.svg"
                        className="h-9 w-9"
                    ></use>
                </svg>
                <img
                    loading="eager"
                    src="/images/landing/symbols/clubhouse.svg"
                    alt="clubhouse"
                    width={36}
                    height={36}
                    className={cn(
                        'h-9 w-9 cursor-pointer opacity-25 mix-blend-normal hover:opacity-50',
                        {
                            'opacity-100 hover:opacity-100':
                                page === SideNavPage.Launchpad,
                            invert:
                                page === SideNavPage.Launchpad ||
                                page === SideNavPage.Services,
                            'invert-0': page === SideNavPage.Investments,
                        }
                    )}
                    onClick={() => handleClick(SideNavPage.Launchpad)}
                />
                <img
                    loading="eager"
                    src="/images/landing/symbols/services.svg"
                    alt="services"
                    width={36}
                    height={36}
                    className={cn(
                        'h-9 w-9 cursor-pointer opacity-25 mix-blend-normal hover:opacity-50',
                        {
                            'opacity-100 hover:opacity-100':
                                page === SideNavPage.Services,
                            invert:
                                page === SideNavPage.Launchpad ||
                                page === SideNavPage.Services,
                            'invert-0': page === SideNavPage.Investments,
                        }
                    )}
                    onClick={() => handleClick(SideNavPage.Services)}
                />
                <img
                    loading="eager"
                    src="/images/landing/symbols/investments.svg"
                    alt="investments"
                    width={36}
                    height={36}
                    className={cn(
                        'h-9 w-9 cursor-pointer opacity-25 mix-blend-normal hover:opacity-50',
                        {
                            'opacity-100 hover:opacity-100':
                                page === SideNavPage.Investments,
                            invert:
                                page === SideNavPage.Launchpad ||
                                page === SideNavPage.Services,
                            'invert-0': page === SideNavPage.Investments,
                        }
                    )}
                    onClick={() => handleClick(SideNavPage.Investments)}
                />
            </div>
        </div>
    );
};

import { Label } from '~/cds/Label';

export const MobileMainDiv = () => {
    return (
        <div className="bg-white xs:mx-[25px] xs:block xs:gap-9 xs:rounded-[40px] xs:px-[27px] xs:pt-[24px] xs:pb-[80px] sm:hidden">
            <div className="bg-white xs:block xs:w-[320px] xs:rounded-[26.69px] xs:border-[9px] xs:border-white sm:hidden">
                <div className="relative h-auto max-w-full pb-[56.25%]">
                    <iframe
                        width="100%"
                        height="100%"
                        src="https://www.youtube.com/embed/tlJVHJE5s8g?controls=0&autoplay=1&loop=1&mute=1&t=365s&rel=0&showinfo=0"
                        title="YouTube video player"
                        className="pointer-events-none absolute top-0 left-0 w-full rounded-[26.69px]"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    ></iframe>
                </div>
            </div>
            <div className="xs:mt-9 xs:space-y-6 xs:px-6">
                <h1 className="font-univers text-black xs:text-[20px] xs:font-bold xs:leading-[100%] xs:tracking-[-0.06em]">
                    Our latest investment: <u>@zoeunlimited</u>
                </h1>
                <div className="flex flex-col">
                    <Label className="text-lg font-medium tracking-[-0.02em]">
                        2.51M subscribers on Youtube
                    </Label>
                    <Label className="text-lg font-medium tracking-[-0.02em]">
                        200k followers on Instagram
                    </Label>
                    <Label className="text-lg font-medium tracking-[-0.02em]">
                        130k followers on TikTok
                    </Label>
                </div>
            </div>
        </div>
    );
};

import { Link } from '@remix-run/react';
import { Button } from '~/cds/Button';
import { NewsTabs } from '~/common/components/Landing';
import { Marquee } from '~/common/components/Marquee';
import { URLS } from '~/common/constants/urls';
import { cn } from '~/common/utils/styles';
import { InvestorItem } from './components';
import type { InvestorType } from './components/InvestorItem';

const InvestorList: InvestorType[] = [
    {
        name: 'Andreessen Horowitz',
        description: 'Venture capital',
        image: '/images/landing/investors/a16z.svg',
        size: 'sm',
    },
    {
        name: 'Paris Hilton',
        description: 'Media personality',
        image: '/images/landing/investors/parishilton.png',
        size: 'lg',
        padding: '23px 33px 0px 67px',
    },
    {
        name: 'SV Angel',
        description: 'Venture capital',
        image: '/images/landing/investors/sva.svg',
        size: 'sm',
    },
    {
        name: 'Garry Tan',
        description: 'President and CEO of Y-Combinator',
        image: '/images/landing/investors/garrytan.png',
        size: 'lg',
    },
    {
        name: 'Initialized Capital',
        description: 'Venture capital',
        image: '/images/landing/investors/initialized.svg',
        size: 'sm',
    },
    {
        name: 'Liam Payne',
        description: 'Singer',
        image: '/images/landing/investors/liampayne.png',
        size: 'lg',
        padding: '0px 0px 0px 24px',
    },
    {
        name: 'Liquid 2 Ventures',
        description: 'Venture capital',
        image: '/images/landing/investors/l2ventures.svg',
        size: 'sm',
    },
    {
        name: 'Chainsmokers',
        description: 'Electronic DJ and production duo',
        image: '/images/landing/investors/chainsmokers.png',
        size: 'lg',
    },
    {
        name: 'Mantis',
        description: 'Venture capital',
        image: '/images/landing/investors/mantis.svg',
        size: 'sm',
    },
    {
        name: 'Nikil Viswanathan',
        description: 'CEO of Alchemy',
        image: '/images/landing/investors/nikil.png',
        size: 'lg',
        padding: '10.529px 68.764px 0px 8px',
    },
    {
        name: 'M13',
        description: 'Venture capital',
        image: '/images/landing/investors/m13.svg',
        size: 'sm',
    },
    {
        name: 'Qasar Younis',
        description: 'CEO of Applied Intuition',
        image: '/images/landing/investors/qasaryounis.png',
        size: 'lg',
        padding: '12px 15px 0px 27px',
    },
    {
        name: 'Antifund',
        description: 'Venture capital',
        image: '/images/landing/investors/antifund.svg',
        size: 'sm',
    },
    {
        name: 'Immad Akhund',
        description: 'CEO of Mercury',
        image: '/images/landing/investors/immad.png',
        size: 'lg',
    },
    {
        name: 'Audacious Ventures',
        description: 'Venture capital',
        image: '/images/landing/investors/audaciousventures.svg',
        size: 'sm',
    },
];

export const Investors = ({
    avalonOnboardingEnabled,
}: {
    avalonOnboardingEnabled: boolean;
}) => {
    return (
        <section className="relative overflow-hidden">
            <div
                className={cn(
                    'relative flex w-full flex-col items-start bg-[#FFF500]',
                    'xs:h-[749px]',
                    'sm:h-[1150px]'
                )}
            >
                <div
                    className={cn(
                        'flex w-full justify-between',
                        'xs:px-8 xs:pt-20',
                        'sm:px-[211px] sm:pt-[200px]'
                    )}
                >
                    <h1
                        className={cn(
                            'font-univers font-bold tracking-[-6.5%] text-black',
                            'xs:text-center xs:text-[32px] xs:leading-[28px]',
                            'sm:text-start sm:text-[56px] sm:leading-[48px]'
                        )}
                    >
                        Our incredible investors
                    </h1>
                </div>
                <Marquee
                    className="flex xs:mt-[60px] sm:mt-20"
                    speed={100}
                    autoFill
                >
                    {InvestorList.map((investor, index) => (
                        <InvestorItem key={index} investor={investor} />
                    ))}
                </Marquee>
            </div>
            <NewsTabs />
            <div
                className={cn(
                    'flex w-full items-end bg-black',
                    'xs:h-[749px]',
                    'sm:h-[1150px]'
                )}
            >
                <div
                    className={cn(
                        'flex w-full flex-col items-center justify-center',
                        'xs:space-y-[32.45px] xs:px-8 xs:pb-[144.5px]',
                        'sm:space-y-[45px] sm:px-[341px] sm:pb-[163px]'
                    )}
                >
                    <img
                        loading="eager"
                        src="/images/logo/creatordao_white_logo.svg"
                        alt="CreatorDAO"
                        width={124}
                        height={64}
                        className={cn(
                            'xs:h-[64px] xs:w-[123.73px]',
                            'sm:h-[119.08px] sm:w-[230.73px]'
                        )}
                    />
                    <h1
                        className={cn(
                            'text-center font-univers tracking-[-6.5%] text-white',
                            'xs:text-[32px] xs:font-bold xs:leading-[28px]',
                            'sm:text-[60px] sm:font-extrabold sm:leading-[55px]',
                            'lg:w-[920px]'
                        )}
                    >
                        We help creators launch & grow incredible businesses
                    </h1>
                    {avalonOnboardingEnabled && (
                        <Button
                            variant="tertiary"
                            size="lg"
                            as={Link}
                            to={URLS.PUBLIC.AUTH.SIGNUP}
                            className="xs:w-[134px] sm:w-[178px]"
                        >
                            Join us
                        </Button>
                    )}
                </div>
            </div>
        </section>
    );
};

import { Button } from '~/cds/Button';
import { Typography } from '~/cds/Typography';
import { cn } from '~/common/utils/styles';
import { ParallaxSideNav, SideNavPage } from '../ParallaxSideNav';

export const Services = () => {
    return (
        <section id={SideNavPage.Services} className="relative overflow-y-clip">
            <ParallaxSideNav page={SideNavPage.Services} />
            <div className="relative isolate flex h-screen items-center justify-center gap-20 overflow-hidden bg-overlay-pink">
                <video
                    autoPlay
                    loop
                    muted
                    className="pointer-events-none absolute z-10 h-screen w-full object-cover"
                    typeof="video/mp4"
                    src="/videos/landing/bubble.mp4"
                    playsInline
                    preload="auto"
                    width={640}
                    height={360}
                ></video>
                <div className="relative z-[20] h-full w-full bg-overlay-pink mix-blend-multiply"></div>
                <div
                    className={cn(
                        'absolute z-30 flex h-full flex-col items-center justify-center',
                        'xs:gap-8 xs:px-8',
                        'sm:gap-10 sm:px-[341px]'
                    )}
                >
                    <img
                        src="/images/landing/symbols/services.svg"
                        alt="services"
                        width={36}
                        height={36}
                        className="h-9 w-9 invert xs:block sm:hidden"
                    />
                    <div
                        className={cn(
                            'flex flex-col items-center',
                            'xs:gap-4',
                            'sm:gap-10'
                        )}
                    >
                        <h1
                            className={cn(
                                'text-center font-univers tracking-[-6.5%] text-white',
                                'xs:text-[35px] xs:font-bold xs:leading-[28px]',
                                'sm:text-[70px] sm:font-extrabold sm:leading-[104px]',
                                'md:text-[124px] md:font-extrabold md:leading-[104px]'
                            )}
                        >
                            Services
                        </h1>
                        <Typography
                            intent="heading"
                            size="md"
                            className={cn(
                                'text-center text-white',
                                'xs:text-base xs:font-normal xs:tracking-[-2%]',
                                'sm:max-w-5xl sm:text-[32px] sm:leading-[36px] sm:tracking-[-6%]'
                            )}
                        >
                            We bet you have optimizations that need to be made,
                            but you don’t quite know where to start. We’re happy
                            to help you tackle any part of your journey, from
                            hiring an editor, to launching a product, to
                            adapting your content from one platform to another.
                        </Typography>
                    </div>
                    <Button
                        variant="tertiary"
                        size="lg"
                        className="w-[202px] gap-2 py-4 px-8"
                    >
                        <a
                            href="https://creatordaoapp.typeform.com/to/ekqt1T0H"
                            target="_blank"
                            rel="noreferrer"
                        >
                            Contact us
                        </a>
                    </Button>
                </div>
            </div>
        </section>
    );
};

import * as React from 'react';
import { Label } from '~/cds/Label';
import { cn } from '~/common/utils/styles';

export const MainDiv = () => {
    return (
        <React.Fragment>
            <div
                className={cn(
                    'mt-24 mr-[-105px] w-[680px] rounded-[50px] bg-white p-10',
                    'xs:hidden',
                    'sm:block',
                    '2xl:mr-[-145px] 2xl:w-[960px]'
                )}
            >
                <div className="relative h-auto max-w-full pb-[56.25%]">
                    <iframe
                        width="100%"
                        height="100%"
                        src="https://www.youtube.com/embed/tlJVHJE5s8g?controls=0&autoplay=1&loop=1&mute=1&t=365s&rel=0&showinfo=0&playlist=tlJVHJE5s8g"
                        title="YouTube video player"
                        className="pointer-events-none absolute top-0 left-0 w-full rounded-[35px]"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    ></iframe>
                </div>
            </div>
            <div
                className={cn(
                    'h-[356px] flex-col justify-center rounded-[50px] bg-white py-20 pl-36 pr-10',
                    'xs:hidden',
                    'sm:flex',
                    'xl:h-[400px] xl:w-[710px] xl:pl-48'
                )}
            >
                <h1 className="font-univers text-[35px] font-bold leading-[44px] tracking-[-6%]">
                    Our latest investment:
                    <br />
                    <u>@zoeunlimited</u>
                </h1>
                <div className="mt-4 flex flex-col">
                    <Label className="text-xl font-medium tracking-[-2%]">
                        2.51M subscribers on Youtube
                    </Label>
                    <Label className="text-xl font-medium tracking-[-2%]">
                        200k followers on Instagram
                    </Label>
                    <Label className="text-xl font-medium tracking-[-2%]">
                        130k followers on TikTok
                    </Label>
                </div>
            </div>
        </React.Fragment>
    );
};

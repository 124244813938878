import { Marquee } from '~/common/components/Marquee';

export const HighlightsMarquee = ({
    direction = 'left',
}: {
    direction?: 'left' | 'right';
}) => {
    return (
        <Marquee
            play
            autoFill
            className="flex h-[92px] items-center gap-3 py-8"
            speed={100}
            direction={direction}
        >
            <div className="mr-16 flex space-x-16">
                <label className="uppercase leading-7 tracking-[-2%] text-[#000]">
                    Creator DAO
                </label>
                <img
                    loading="eager"
                    src="/images/logo/creatordao_black_logo.svg"
                    alt="Creator DAO"
                    width="100%"
                    height="28px"
                    className="h-7 w-auto"
                />
                <label className="uppercase leading-7 tracking-[-2%] text-[#000]">
                    Creator DAO
                </label>
                <img
                    loading="eager"
                    src="/images/logo/creatordao_star.svg"
                    alt="Creator DAO Star"
                    width="100%"
                    height="28px"
                    className="h-7 w-auto"
                />
            </div>
        </Marquee>
    );
};

import { Label } from '~/cds/Label';
import { Typography } from '~/cds/Typography';
import { cn } from '~/common/utils/styles';
import { ParallaxSideNav, SideNavPage } from '../ParallaxSideNav';
import { Button } from '~/cds/Button';

export const Launchpad = () => {
    return (
        <section
            id={SideNavPage.Launchpad}
            className="relative overflow-y-clip"
        >
            <ParallaxSideNav page={SideNavPage.Launchpad} />
            <div
                className={cn(
                    'relative isolate flex flex-col gap-16 overflow-hidden bg-blue-500',
                    'xs:h-[973px] xs:pt-[74px]',
                    'sm:h-[1450px] sm:pt-[200px]',
                    'xl:h-[1658px]',
                    '3xl:h-[1712px]'
                )}
            >
                <div
                    className={cn(
                        'flex flex-col items-center',
                        'xs:gap-8 xs:px-8',
                        'sm:gap-10 sm:px-[315px]'
                    )}
                >
                    <img
                        src="/images/landing/symbols/clubhouse.svg"
                        alt="clubhouse"
                        width={36}
                        height={36}
                        className="h-9 w-9 invert xs:block sm:hidden"
                    />
                    <div className="flex flex-col xs:gap-4 sm:gap-10">
                        <h1
                            className={cn(
                                'text-center font-univers tracking-[-6.5%] text-white',
                                'xs:text-[35px] xs:font-bold xs:leading-[28px]',
                                'sm:text-[70px] sm:font-extrabold sm:leading-[104px]',
                                'md:text-[124px] md:font-extrabold md:leading-[104px]'
                            )}
                        >
                            Launchpad
                        </h1>
                        <div
                            className={cn(
                                'items-start',
                                'xs:flex xs:flex-col xs:gap-4',
                                'sm:gap-10',
                                'md:grid md:grid-cols-2'
                            )}
                        >
                            <Typography
                                intent="heading"
                                size="md"
                                className={cn(
                                    'font-medium text-white',
                                    'xs:text-center xs:text-[20px] xs:leading-[24px] xs:tracking-[-6%]',
                                    'sm:text-start sm:text-[32px] sm:leading-[36px] sm:tracking-[-0.06em]'
                                )}
                            >
                                A free online bootcamp focused on helping people
                                launch and grow a profitable long-form YouTube
                                channel.
                            </Typography>
                            <Label
                                className={cn(
                                    'text-xl tracking-[-2%] text-white',
                                    'xs:text-center xs:text-base xs:font-normal',
                                    'sm:text-start sm:text-xl sm:font-medium'
                                )}
                            >
                                Learn how to succeed on YouTube from seasoned
                                professionals who have amassed billions of
                                organic views on YouTube. Each week, you’ll get
                                weekly lessons & challenges designed to help
                                your content grow. At the end of the program,
                                creators can win cash prizes, or ask for an
                                investment and receive one on one mentorship
                                from our partners.
                            </Label>
                        </div>
                    </div>
                    {/*<Button*/}
                    {/*    className="whitespace-nowrap rounded-full bg-white px-4 py-2 text-black shadow"*/}
                    {/*    as={Link}*/}
                    {/*    to={'/launchpad'}*/}
                    {/*>*/}
                    {/*    Apply*/}
                    {/*</Button>*/}
                    <Button
                        variant="tertiary"
                        size="lg"
                        className="w-[202px] gap-2 py-4 px-8"
                    >
                        <a href="https://www.creatordao.com/launchpad">Apply</a>
                    </Button>
                </div>
                <div className="relative h-full w-full justify-center overflow-hidden">
                    <img
                        loading="eager"
                        src="/images/landing/clubhouse/carpet.svg"
                        alt="carpet"
                        width={120}
                        height={120}
                        className={cn(
                            'pointer-events-none absolute left-[50%] max-h-full w-auto max-w-full translate-x-[-50%] transform',
                            'xs:bottom-[16.51px] xs:h-[75.58px]',
                            'sm:bottom-[61px] sm:h-[158px]',
                            'xl:left-[48%] xl:h-[198px]',
                            '2xl:h-[220px]',
                            '3xl:h-[260px]'
                        )}
                    />
                    <img
                        loading="eager"
                        src="/images/landing/clubhouse/girl_pointing.svg"
                        alt="women-on-chair"
                        width={120}
                        height={120}
                        className={cn(
                            'pointer-events-none absolute max-h-full w-auto max-w-full translate-x-[-50%] transform',
                            'xs:left-[57%] xs:bottom-[78.72px] xs:h-[278.35px]',
                            'sm:bottom-[170px] sm:left-[53.5%] sm:h-[530px]',
                            'xl:left-[53%] xl:bottom-[209px] xl:h-[595px]',
                            '2xl:left-[53%] 2xl:h-[655px]',
                            '3xl:left-[52.5%] 3xl:h-[755px]'
                        )}
                    />
                    <img
                        loading="eager"
                        src="/images/landing/clubhouse/cdao_spinner.gif"
                        alt="cdao-logo"
                        width={120}
                        height={120}
                        className={cn(
                            'pointer-events-none absolute left-[50%] w-auto translate-x-[-50%] rotate-[5.67deg] transform',
                            'xs:bottom-[273.26px] xs:h-[68.86px]',
                            'sm:bottom-[533px] sm:h-[146.73px]',
                            'xl:bottom-[679px]',
                            '3xl:bottom-[744px] 3xl:h-[180px]'
                        )}
                    />
                    <img
                        loading="eager"
                        src="/images/landing/clubhouse/carpet_guy.svg"
                        alt="guy-sitting"
                        width={120}
                        height={120}
                        className={cn(
                            'pointer-events-none absolute max-h-full w-auto max-w-full translate-x-[-50%] transform',
                            'xs:bottom-[-11px] xs:right-[-233px] xs:h-[275px]',
                            'sm:bottom-[10px] sm:left-[69%] sm:h-[470px]',
                            'xl:left-[67%] xl:h-[554px]',
                            '2xl:left-[70%] 2xl:h-[580px]',
                            '3xl:left-[66%] 3xl:h-[680px]'
                        )}
                    />
                    <img
                        loading="eager"
                        src="/images/landing/clubhouse/guy_right_side.svg"
                        alt="guy-on-the-right"
                        width={120}
                        height={120}
                        className={cn(
                            'pointer-events-none absolute right-0 bottom-0 max-h-full w-auto max-w-full',
                            'xs:hidden',
                            'sm:block sm:h-[840px]',
                            'xl:h-[1100px]',
                            '2xl:h-[1400px]'
                        )}
                    />
                    <img
                        loading="eager"
                        src="/images/landing/clubhouse/guy_onchair.svg"
                        alt="guy-on-the-chair"
                        width={120}
                        height={120}
                        className={cn(
                            'pointer-events-none absolute bottom-[112px] max-h-full w-auto max-w-full translate-x-[-50%] transform',
                            'xs:hidden',
                            'sm:left-[30%] sm:block sm:h-[620px]',
                            'xl:left-[32%] xl:h-[747px]',
                            '2xl:left-[31.5%] 2xl:h-[760px]',
                            '3xl:left-[36%] 3xl:h-[900px]'
                        )}
                    />
                    <img
                        loading="eager"
                        src="/images/landing/clubhouse/girl_left_side.svg"
                        alt="women-on-the-left"
                        width={120}
                        height={120}
                        className={cn(
                            'pointer-events-none absolute bottom-0 max-h-full w-auto max-w-full',
                            'xs:left-[-26px] xs:h-[413px]',
                            'sm:left-[24px] sm:h-[820px]',
                            'xl:h-[1080px]',
                            '2xl:h-[1020px]',
                            '3xl:h-[1400px]'
                        )}
                    />
                </div>
            </div>
        </section>
    );
};

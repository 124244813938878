import { Link } from '@remix-run/react';
import { Button } from '~/cds/Button';
import { URLS } from '~/common/constants/urls';
import { cn } from '~/common/utils/styles';

export const Hero = ({
    avalonOnboardingEnabled,
}: {
    avalonOnboardingEnabled: boolean;
}) => {
    return (
        <section
            className={cn(
                'relative flex flex-col items-center justify-center overflow-hidden bg-[#000]',
                'xs:px-6 sm:px-[120px] lg:px-[285.5px]'
            )}
            style={{ height: 'calc(100vh - 88px)' }}
        >
            <video
                autoPlay
                loop
                muted
                className="pointer-events-none absolute z-10 w-full bg-black object-cover"
                style={{ height: '100%' }}
                typeof="video/mp4"
                src="/videos/landing/creatordao_clip.mp4"
                playsInline
                preload="auto"
                width={640}
                height={360}
            ></video>
            <div className="relative z-30 flex flex-col items-center text-center xs:gap-6 sm:gap-10">
                <h1
                    className={cn(
                        'font-univers text-white drop-shadow-[0_0_80px_rgba(0,0,0,0.5)]',
                        'xs:text-[35px] xs:font-bold xs:leading-[32px] xs:tracking-[-6.5%]',
                        'sm:text-[70px] sm:font-extrabold sm:leading-[75px] sm:tracking-[-7%]',
                        'lg:w-[1157px] lg:text-[80px]'
                    )}
                >
                    We help creators launch & grow incredible businesses
                </h1>
                {avalonOnboardingEnabled && (
                    <Button
                        variant="tertiary"
                        size="lg"
                        as={Link}
                        to={URLS.PUBLIC.AUTH.SIGNUP}
                        className={cn(
                            'w-[190px] gap-2',
                            'xs:py-3 xs:px-6',
                            'sm:py-4 sm:px-8'
                        )}
                    >
                        Sign up
                    </Button>
                )}
            </div>
        </section>
    );
};

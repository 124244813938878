import { Label } from '~/cds/Label';
import { cn } from '~/common/utils/styles';

export type InvestorType = {
    name: string;
    description: string;
    image: string;
    size: 'sm' | 'lg';
    padding?: string;
};

export const InvestorItem = ({ investor }: { investor: InvestorType }) => {
    return (
        <div
            className={cn(
                'relative flex flex-col',
                'xs:mx-4 xs:gap-y-[15.84px]',
                'sm:mx-8 sm:gap-y-5'
            )}
        >
            <div
                className={cn(
                    'inline-block justify-center overflow-hidden bg-black',
                    'xs:h-[188px] xs:rounded-[50.67px_50.67px_50.67px_0px]',
                    'sm:h-[320px] sm:rounded-[96px_96px_96px_0px]',
                    {
                        'items-end xs:w-[258px] sm:w-[440px]':
                            investor.size === 'lg',
                        'items-center xs:w-[179px] xs:px-[34.84px] sm:w-[280px] sm:px-[55px]':
                            investor.size === 'sm',
                        [`p-[${investor.padding?.replace(/ /g, '_')}]`]:
                            investor.padding,
                    }
                )}
            >
                <img
                    loading="eager"
                    src={investor.image}
                    alt={investor.name}
                    width={120}
                    height={120}
                    className={cn(
                        'pointer-events-none h-full w-full',
                        {
                            'object-cover object-top brightness-125 grayscale':
                                investor.size === 'lg',
                        },
                        {
                            'object-contain': investor.size === 'sm',
                        }
                    )}
                />
            </div>
            <div className="flex flex-col">
                <Label
                    className={cn(
                        'tracking-[-2%] text-black',
                        'xs:text-[14.25px] xs:leading-[19px]',
                        'sm:text-lg'
                    )}
                >
                    {investor.name}
                </Label>
                <Label
                    className={cn(
                        'tracking-[-2%] text-black',
                        'xs:text-[14.25px] xs:leading-[19px]',
                        'sm:text-lg'
                    )}
                >
                    / {investor.description}
                </Label>
            </div>
        </div>
    );
};

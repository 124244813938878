import { Link } from '@remix-run/react';
import { Button } from '~/cds/Button';
import { Typography } from '~/cds/Typography';
import { cn } from '~/common/utils/styles';
import { ParallaxSideNav, SideNavPage } from '../ParallaxSideNav';
import { MainDiv, MobileMainDiv } from './components';

export const Investments = () => {
    return (
        <section
            id={SideNavPage.Investments}
            className="relative overflow-y-clip"
        >
            <ParallaxSideNav page={SideNavPage.Investments} />
            <div
                className={cn(
                    'relative isolate flex flex-col gap-16 overflow-hidden bg-[#12FF7F]',
                    'xs:py-[90px]',
                    'sm:py-[200px]'
                )}
            >
                <div
                    className={cn(
                        'flex flex-col items-center',
                        'xs:gap-8 xs:px-8',
                        'sm:gap-10 sm:px-[315px]'
                    )}
                >
                    <img
                        src="/images/landing/symbols/investments.svg"
                        alt="investments"
                        width={36}
                        height={36}
                        className="h-9 w-9 xs:block sm:hidden"
                    />
                    <div className="flex flex-col items-center xs:gap-4 sm:gap-10">
                        <h1
                            className={cn(
                                'text-center font-univers tracking-[-6.5%] text-black',
                                'xs:text-[35px] xs:font-bold xs:leading-[28px]',
                                'sm:text-[70px] sm:font-extrabold sm:leading-[104px]',
                                'md:text-[124px] md:font-extrabold md:leading-[104px]'
                            )}
                        >
                            Investments
                        </h1>
                        <Typography
                            intent="heading"
                            size="md"
                            className={cn(
                                'text-center text-black sm:max-w-6xl',
                                'xs:text-[20px] xs:leading-[24px]',
                                'sm:text-[32px] sm:font-medium sm:leading-[36px]'
                            )}
                        >
                            Super-boost a new or existing business. If you have
                            a great idea, we’d love to invest. Need a team to
                            help you scale your business? We’ve got you covered
                        </Typography>
                        <Button
                            variant="primary"
                            size="lg"
                            className="w-[190px] gap-2 py-4 px-8"
                        >
                            <a
                                href="https://creatordaoapp.typeform.com/to/OHtaQJXb"
                                target="_blank"
                                rel="noreferrer"
                            >
                                Contact us
                            </a>
                        </Button>
                    </div>
                </div>
                <div className="relative flex h-full justify-center">
                    <MainDiv />
                    <MobileMainDiv />
                    <Link
                        to="https://facebook.com"
                        target="_blank"
                        rel="noreferrer noopener"
                        className={cn(
                            'absolute',
                            'xs:top-[11%] xs:left-[-6%]',
                            'sm:left-[77%] sm:top-[-12%]',
                            'xl:left-[78%]',
                            '2xl:top-[-9%]',
                            '3xl:left-[70.5%]'
                        )}
                    >
                        <img
                            loading="eager"
                            src="/images/landing/social_media/facebook.svg"
                            alt="facebook"
                            width="104"
                            height="105"
                            className={cn(
                                'xs:h-[68.4px] xs:w-[68.4px]',
                                'sm:h-[105px] sm:w-[104px]'
                            )}
                        />
                    </Link>
                    <Link
                        to="https://youtube.com/@zoeunlimited"
                        target="_blank"
                        rel="noreferrer noopener"
                        className={cn(
                            'absolute',
                            'xs:top-[-9%] xs:left-[60%]',
                            'sm:left-[35%] sm:top-[5%]',
                            'xl:left-[40%] xl:top-[4%]',
                            '3xl:left-[43%]'
                        )}
                    >
                        <img
                            loading="eager"
                            src="/images/landing/social_media/youtube.svg"
                            alt="youtube"
                            width="99"
                            height="102"
                            className={cn(
                                'xs:h-[68px] xs:w-[65.9px]',
                                'sm:h-[102px] sm:w-[99px]'
                            )}
                        />
                    </Link>
                    <Link
                        to="https://www.snapchat.com/add/zoeunlimited"
                        target="_blank"
                        rel="noreferrer noopener"
                        className={cn(
                            'absolute',
                            'xs:top-[38%] xs:right-[-6%]',
                            'sm:left-[87%] sm:top-[35%]',
                            'lg:left-[89%]',
                            'xl:left-[85%] xl:top-[30%]',
                            '2xl:left-[87.5%]',
                            '3xl:left-[78%]'
                        )}
                    >
                        <img
                            loading="eager"
                            src="/images/landing/social_media/snapchat.svg"
                            alt="snapchat"
                            width="84"
                            height="83"
                            className={cn(
                                'xs:h-[71.96px] xs:w-[73.22px]',
                                'sm:h-[83px] sm:w-[84px]'
                            )}
                        />
                    </Link>
                    <Link
                        to="https://tiktok.com/@zoeunlimited"
                        target="_blank"
                        rel="noreferrer noopener"
                        className={cn(
                            'absolute',
                            'xs:top-[94%] xs:left-[19%]',
                            'sm:top-[63%] sm:left-[60%]',
                            'xl:top-[55%] xl:left-[65%]',
                            '3xl:left-[60%]'
                        )}
                    >
                        <img
                            loading="eager"
                            src="/images/landing/social_media/tiktok.svg"
                            alt="tiktok"
                            width="96"
                            height="96"
                            className={cn(
                                'xs:h-[79.08px] xs:w-[79.08px]',
                                'sm:h-[96px] sm:w-[96px]'
                            )}
                        />
                    </Link>
                    <Link
                        to="https://instagram.com/zoeunlimited"
                        target="_blank"
                        rel="noreferrer noopener"
                        className={cn(
                            'absolute',
                            'xs:bottom-[-14%] xs:left-[47%]',
                            'sm:top-[80%] sm:left-[70%]',
                            'xl:top-[70%] xl:left-[73%]',
                            '3xl:left-[65.5%]'
                        )}
                    >
                        <img
                            loading="eager"
                            src="/images/landing/social_media/instagram.svg"
                            alt="instagram"
                            width="75"
                            height="73"
                            className={cn(
                                'xs:h-[44.74px] xs:w-[45.52px]',
                                'sm:h-[73px] sm:w-[75px]'
                            )}
                        />
                    </Link>
                </div>
            </div>
        </section>
    );
};

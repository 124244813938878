import { cn } from '~/common/utils/styles';
import { HighlightsMarquee } from './components';

export const Highlights = () => {
    return (
        <section className="relative overflow-hidden">
            <HighlightsMarquee />
            <div className="flex flex-col items-center justify-center space-y-10 py-[120px] xs:px-8">
                <h1
                    className={cn(
                        'text-center font-univers font-bold tracking-[-6.5%] text-black',
                        'xs:w-full xs:text-[32px] xs:leading-[30px]',
                        'sm:max-w-[1125px] sm:text-[56px] sm:leading-[48px]'
                    )}
                >
                    You’re not just a creator.
                    <br />
                    You’re a founder & entrepreneur.
                </h1>
                <p
                    className={cn(
                        'text-center text-xl tracking-[-2%] text-black',
                        'xs:w-full xs:font-normal',
                        'sm:max-w-[824px] sm:px-0 sm:font-medium'
                    )}
                >
                    At CreatorDAO, we treat you like the CEO you are. We’re a
                    group of creators who have leveraged social media to launch
                    thriving businesses, raise VC capital, and create products
                    that people really love. Our mission is to equip creators
                    with whatever they need to grow successful businesses. 
                </p>
            </div>
            <HighlightsMarquee direction="right" />
        </section>
    );
};

import type { DataFunctionArgs, MetaFunction } from '@remix-run/node';
import * as React from 'react';
import { toast } from 'react-toastify';
import {
    Launchpad,
    Hero,
    Highlights,
    Investments,
    Investors,
    Services,
} from '~/components/landing';
import { getFlags } from '~/common/utils/posthog';
import { getUserId } from '~/session.server';
import { useTypedLoaderData } from 'remix-typedjson';

export const meta: MetaFunction = () => {
    return {
        title: 'CreatorDAO',
        'theme-color': '#0E0E0D',
    };
};

export const loader = async ({
    request,
}: DataFunctionArgs): Promise<{
    flags: Record<string, string | boolean>;
    missingAvalonAccess: boolean;
}> => {
    const url = new URL(request.url);
    const missingAvalonAccess = JSON.parse(
        url.searchParams.get('missing_avalon_access') || 'false'
    );

    const userId = await getUserId(request);
    const flags = await getFlags(userId || '');
    return {
        flags,
        missingAvalonAccess,
    };
};

export default function LandingPage() {
    const { flags, missingAvalonAccess } = useTypedLoaderData<typeof loader>();
    const avalonOnboardingEnabled = flags['avalon_onboarding'] as boolean;

    if (missingAvalonAccess) {
        toast.success('You have successfully signed up');
    }

    return (
        <React.Fragment>
            <Hero avalonOnboardingEnabled={avalonOnboardingEnabled} />
            <Highlights />
            <Launchpad />
            <Services />
            <Investments />
            <Investors avalonOnboardingEnabled={avalonOnboardingEnabled} />
        </React.Fragment>
    );
}
